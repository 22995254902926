import React from "react"
import { MarginStyle } from "../../../types"

import "./style.scss"

interface CustomerInputProps {
  style?: MarginStyle
  title: string
  value: string
  type: string
  setValue: (arg0: string) => void
  maxLength: number
  placeholder: string
}

const CustomerInput = ({ style, title, value, setValue, placeholder, maxLength, type }: CustomerInputProps) => {
  return (
    <div className="v-box" style={{ marginBottom: 15, ...style }}>
      <span className="customer-input-title-text">{title}</span>
      <input
        className="customer-input-text-input"
        type={type}
        value={value}
        onChange={event => setValue(event.target.value)}
        placeholder={placeholder}
        maxLength={maxLength}
      />
    </div>
  )
}

export default CustomerInput
