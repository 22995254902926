import { Button } from "@material-ui/core"
import React, { useContext } from "react"
import { Product } from "../../../API"
import { globalContext } from "../../../contexts/GlobalContext"
import { numberWithCommas } from "../../../functions"
import { CartItem, CountValue } from "../../../types"
import { CLOUD_FRONT_URI, CURRENCY_WON } from "../../../values"
import ItemCounter from "../ItemCounter"
import "./style.scss"

interface CartProductItem {
  cartItem: CartItem
}

const CartProductItem = ({ cartItem }: CartProductItem) => {
  const context = useContext(globalContext)
  const { setCartItemCount, deleteCartItem } = context?.deliveryReducer

  function onClickCounterButton(value: CountValue) {
    if (value == CountValue.INCREASE) {
      setCartItemCount(cartItem, cartItem.count + 1)
    } else {
      if (cartItem.count > 1) {
        setCartItemCount(cartItem, cartItem.count - 1)
      }
    }
  }

  function isEventActive(product: Product) {
    if (product.event) {
      if (
        product.event.isActive &&
        new Date(product.event.startDate!) <= new Date() &&
        new Date(product.event.endDate!) >= new Date()
      ) {
        return true
      }
    }

    return false
  }

  const pcVersion = (
    <tr>
      <td className="no-border-td">
        <div className="h-box">
          <img className="cart-item-image" src={`${CLOUD_FRONT_URI}/${cartItem.product.uri}`} />
          <div className="v-box cart-item-info-container">
            <span className="cart-item-name-text">{cartItem.product.name}</span>
            <span className="cart-item-individual-price-text">
              {isEventActive(cartItem.product) &&
              cartItem.product.eventPercentage &&
              cartItem.product.eventPrice
                ? numberWithCommas(cartItem.product.eventPrice)
                : numberWithCommas(cartItem.product.price)}
              {CURRENCY_WON}
            </span>
          </div>
        </div>
      </td>
      <td className="no-border-td">
        <ItemCounter count={cartItem.count} onClick={onClickCounterButton} />
      </td>
      <td className="no-border-td">
        <div className="v-box">
          <span className="cart-item-total-price-text">
            {isEventActive(cartItem.product) &&
            cartItem.product.eventPercentage &&
            cartItem.product.eventPrice
              ? numberWithCommas(cartItem.product.eventPrice * cartItem.count)
              : numberWithCommas(cartItem.product.price * cartItem.count)}
            {CURRENCY_WON}
          </span>
          <span onClick={() => deleteCartItem(cartItem)} className="cart-item-delete-text">
            삭제
          </span>
        </div>
      </td>
    </tr>
  )

  const mobileVersion = (
    <div className="v-box" style={{ 
      padding: "20px 0px", 
      margin: "0px 20px",
      borderBottom: "1px solid rgba(0,0,0,0.1)" 
    }}>
      <div className="h-box-spaced">
        <div className="h-box">
          <img className="cart-item-image" src={`${CLOUD_FRONT_URI}/${cartItem.product.uri}`} />
          <div className="v-box cart-item-info-container">
            <span className="cart-item-name-text">{cartItem.product.name}</span>
            <span className="cart-item-individual-price-text">
              {isEventActive(cartItem.product) &&
              cartItem.product.eventPercentage &&
              cartItem.product.eventPrice
                ? numberWithCommas(cartItem.product.eventPrice)
                : numberWithCommas(cartItem.product.price)}
              {CURRENCY_WON}
            </span>
          </div>
        </div>
        <span
          onClick={() => deleteCartItem(cartItem)}
          className="cart-item-delete-text"
          style={{
            padding: "5px 10px",
            border: "1px solid rgba(0,0,0,0.2)",
            boxShadow: "0px 1px 7px rgba(0,0,0,0.1)",
            minWidth: 46
          }}
        >
          삭제
        </span>
      </div>
      <div className="h-box-spaced" style={{ marginTop: 20 }}>
        <ItemCounter count={cartItem.count} onClick={onClickCounterButton} />
        <span className="cart-item-total-price-text">
          {isEventActive(cartItem.product) &&
          cartItem.product.eventPercentage &&
          cartItem.product.eventPrice
            ? numberWithCommas(cartItem.product.eventPrice * cartItem.count)
            : numberWithCommas(cartItem.product.price * cartItem.count)}
          {CURRENCY_WON}
        </span>
      </div>
    </div>
  )

  if (context?.isPc) return pcVersion
  else return mobileVersion
}

export default CartProductItem
