import { css } from "@emotion/react"
import React, { useContext, useEffect, useRef, useState } from "react"
import PageTitleText from "../../components/atom/PageTitleText"
import SubmitButton from "../../components/molecule/SubmitButton"
import CartTable from "../../components/organism/CartTable"
import Layout from "../../components/organism/Layout"
import PrivacyModal from "../../components/organism/PrivacyModal"
import CountText from "../../components/shoppingCart/CountText"
import CurrencyText from "../../components/shoppingCart/CurrencyText"
import InputSet from "../../components/shoppingCart/InputSet"
import { globalContext } from "../../contexts/GlobalContext"
import { numberWithCommas } from "../../functions"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import {
  CART_SECTION_TITLE,
  DELIVERY_SECTION_TITLE,
  INVALID_PHONE_TEXT,
  LACK_ADDRESS_TEXT,
  LACK_NAME_TEXT,
  LACK_PHONE_TEXT,
  TOO_LONG_NAME_TEXT,
  TOO_LONG_ADDRESS_TEXT,
  INVALID_POINT_NUMBER_TEXT,
  TOO_LONG_MESSAGE_TEXT,
  NOT_ENOUGH_PRICE,
  PAGE,
} from "../../values"
import "./style.scss"
import { PaymentType } from "../../API"

const deliveryStyle = css`
  font-size: 13px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 5px 10px;
  background-color: rgba(181, 56, 67, 0.8);
  color: white;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.2);
`

const cartTableContainer = css`
  height: 764px;
  overflow: scroll;

  @media (max-width: 1024px) {
    display: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`

const cartTableContainerForMobile = css`
  display: none;

  @media (max-width: 1024px) {
    display: block;
    height: 100%;
    overflow: scroll;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`

const errorMessage = css`
  font-size: 13px;
  color: red;
`

const ShoppingCart = () => {
  const context = useContext(globalContext)
  const [privacyModalOn, setPrivacyModalOn] = useState<boolean>(false)
  const [isAboveOrder, setIsAboveOrder] = useState<boolean>(true)
  const isFirstRender = useRef<boolean>(true)

  useEffect(() => {
    const nowScrollHeightHandler = () => {
      let element = document.querySelector("#scroll-point")
      if (element) {
        let relativeHeight = element.getBoundingClientRect().top
        setIsAboveOrder(nowState => {
          if (relativeHeight >= 280 && !nowState) {
            // console.log(`${relativeHeight} ${nowState}`)
            return true
          } else if (relativeHeight < 280 && nowState) {
            // console.log(`${relativeHeight} ${nowState}`)
            return false
          } else {
            return nowState
          }
        })
      }
    }
    if (isFirstRender.current) {
      isFirstRender.current = false
      window.addEventListener("scroll", nowScrollHeightHandler)
    }

    return () => window.removeEventListener("scroll", nowScrollHeightHandler)
  }, [])

  const orderInformationPart = () => {
    const deliverableMessageUI = () => (
      <div className="h-box" style={{ marginBottom: 5, alignItems: "center" }}>
        <div className="checkmark-container" style={{ marginRight: 10 }}>
          <img src={require("../../images/checkmark.svg")} style={{ margin: 0 }} />
        </div>
        <div className="v-box">
          <span className="description-text">현재 주문이 가능합니다.</span>
          <span className="description-text">
            {!context?.storeHourHook.storeHour?.isForceDisabled &&
              `(배달가능시간: ${context?.storeHourHook?.storeHour?.startTime} ~ ${context?.storeHourHook?.storeHour?.endTime})`}
          </span>
        </div>
      </div>
    )

    const notDeliverableMessageUI = () => (
      <div className="h-box" style={{ marginBottom: 25, alignItems: "center" }}>
        <div
          className="checkmark-container"
          style={{
            marginRight: 10,
            backgroundColor: "red",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <img
            src={require("../../images/icons8-multiply.svg")}
            style={{ margin: 0, width: 15, height: 15 }}
          />
        </div>
        <div className="v-box">
          <span className="description-text">현재 주문이 불가능합니다. 죄송합니다.</span>
          <span className="description-text">
            {!context?.storeHourHook.storeHour?.isForceDisabled &&
              `(배달가능: ${context?.storeHourHook?.storeHour?.startTime} ~ ${context?.storeHourHook?.storeHour?.endTime})`}
          </span>
        </div>
      </div>
    )

    const notDeliverableReasonUI = () => (
      <span
        className="description-text"
        style={{
          color:
            context?.deliveryReducer.validateDelivery() === "OK" &&
            context?.storeHourHook.isDeliverable()
              ? "black"
              : "red",
          marginTop: 15,
          alignSelf: "flex-end",
          paddingBottom: 10,
        }}
      >
        {context?.storeHourHook.isDeliverable()
          ? context!.deliveryReducer.validateDelivery()
          : "배달가능시간이 아닙니다"}
      </span>
    )

    const privacyPolicyUI = () => (
      <>
        <span className="private-policy-text">
          주문 시엔 효자콜마트의{" "}
          <span
            onClick={() => {
              setPrivacyModalOn(true)
            }}
          >
            개인정보취급방침
          </span>
          을 읽고 동의했음을 의미합니다.
        </span>
        <PrivacyModal isOpen={privacyModalOn} setIsOpen={setPrivacyModalOn} />
      </>
    )

    return (
      <div className="v-box delivery-info-container" style={{ padding: 20 }}>
        <div className="v-box">
          <PageTitleText title={DELIVERY_SECTION_TITLE} id="scroll-point" />
          <InputSet />
          {context?.storeHourHook?.isDeliverable()
            ? deliverableMessageUI()
            : notDeliverableMessageUI()}
        </div>

        <div className="v-box">
          {notDeliverableReasonUI()}

          <SubmitButton />
          {privacyPolicyUI()}
        </div>
      </div>
    )
  }

  function getHeaderButtonState() {
    if (!context?.storeHourHook.isDeliverable()) {
      return <span css={errorMessage}>배달가능시간이 아닙니다</span>
    } else if (context.deliveryReducer.getCartItemTotalPrice() < 1) {
      return <span css={errorMessage}>물건을 최소 하나 담아주세요</span>
    } else if (context.deliveryReducer.isSending) {
      return <span>접수중</span>
    } else if (isAboveOrder) {
      return (
        <span
          css={deliveryStyle}
          onClick={() => {
            let element = document.querySelector("#scroll-point")
            if (element) {
              let bodyRect = document.body.getBoundingClientRect().top
              let scrollPoint = element.getBoundingClientRect().top - bodyRect - 210
              window.scrollTo({
                top: scrollPoint,
                behavior: "smooth",
              })
            }
          }}
        >
          주문서로 이동
        </span>
      )
    } else if (!(context?.deliveryReducer.validateDelivery() === "OK")) {
      return <span>{context!.deliveryReducer.validateDelivery()}</span>
    } else {
      return (
        <span
          css={deliveryStyle}
          onClick={async () => {
            if (context?.storeHourHook.isDeliverable()) {
              let alertMessage = ""

              alertMessage +=
                "이름 : " + context.deliveryReducer.deliveryStates.delivery.customer.name + "\n"
              alertMessage +=
                "주소 : " + context.deliveryReducer.deliveryStates.delivery.customer.address1 + "\n"
              alertMessage +=
                "전화번호 : " +
                context.deliveryReducer.deliveryStates.delivery.customer.phone +
                "\n"
              alertMessage +=
                "결제방법 : " +
                (context.deliveryReducer.deliveryStates.delivery.paymentType === PaymentType.CASH
                  ? "현금결제"
                  : "카드결제") +
                "\n"
              alertMessage +=
                "포인트번호 : " +
                context.deliveryReducer.deliveryStates.delivery.pointNumber +
                "\n\n"
              alertMessage += "상기의 정보로 주문을 접수하시겠습니까?"

              if (confirm(alertMessage)) {
                context?.deliveryReducer.submitDelivery()
              }
            }
          }}
        >
          주문하기
        </span>
      )
    }

    // {isAboveOrder ? (
    //   <span
    //     css={deliveryStyle}
    //     onClick={() => {
    //       let element = document.querySelector("#scroll-point")
    //       if (element) {
    //         let bodyRect = document.body.getBoundingClientRect().top
    //         let scrollPoint = element.getBoundingClientRect().top - bodyRect - 210
    //         window.scrollTo({
    //           top: scrollPoint,
    //           behavior: "smooth",
    //         })
    //       }
    //     }}
    //   >
    //     주문서로 이동
    //   </span>
    // ) : !(context?.deliveryReducer.validateDelivery() === "OK") ||
    //   !context.storeHourHook.isDeliverable() ||
    //   context.deliveryReducer.isSending ? (
    //   <span>
    //     {context?.storeHourHook.isDeliverable()
    //       ? context!.deliveryReducer.validateDelivery()
    //       : "배달가능시간이 아닙니다"}
    //   </span>
    // ) : (
    //   <button
    //     css={deliveryStyle}
    //   >
    //     주문하기
    //   </button>
    // )}
  }

  return (
    <Layout nowPage={PAGE.SHOPPING_CART}>
      <div className="container">
        <div className="shopping-cart-container">
          <div
            className="v-box"
            style={{
              paddingTop: 20,
              paddingLeft: 15,
              borderBottom: "1px solid rgba(0,0,0,0.3)",
            }}
          >
            <PageTitleText title={CART_SECTION_TITLE} />
            <CountText />
          </div>
          <div css={cartTableContainer}>
            <CartTable />
          </div>
          <div
            className="v-box shopping-cart-price-container-desktop-only"
            style={{
              padding: "5px 15px",
              borderTop: "1px solid rgba(0,0,0,0.3)",
            }}
          >
            <div
              className="h-box shopping-cart-price-info-container"
              style={{
                justifyContent: "space-between",
              }}
            >
              <div
                className="h-box"
                style={{
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    fontWeight: 500,
                    fontSize: 14,
                    marginRight: 5,
                    marginTop: 1,
                  }}
                >
                  총가
                </span>
                <div className="h-box">
                  <CurrencyText />
                  <span>원</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="v-box shopping-cart-price-container"
          style={{
            padding: "5px 15px",
          }}
        >
          <div
            className="h-box shopping-cart-price-info-container"
            style={{
              justifyContent: "space-between",
            }}
          >
            <div
              className="h-box"
              style={{
                alignItems: "center",
              }}
            >
              <span
                style={{
                  fontWeight: 500,
                  fontSize: 14,
                  marginRight: 5,
                  marginTop: 1,
                }}
              >
                총
              </span>
              <CurrencyText />
            </div>

            {getHeaderButtonState()}
          </div>
        </div>
        <div css={cartTableContainerForMobile}>
          <CartTable />
        </div>
        {orderInformationPart()}
      </div>
    </Layout>
  )
}

export default ShoppingCart
