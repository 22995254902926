import React from "react"
import { MarginStyle } from "../../../types"
import { PaymentType } from "../../../API"

const styles = require("./style.module.scss")

interface PaymentMethodProps {
  style?: MarginStyle
  value: PaymentType
  setValue: (paymentMethod: PaymentType) => void
}

const PaymentMethod = ({ style, value, setValue }: PaymentMethodProps) => {
  return (
    <select 
      value={value} 
      onChange={e => setValue(e.target.value)} 
      style={{
        ...style,
        alignSelf: "baseline",
        padding: 5,
        fontSize: 15
      }}>
      <option value={PaymentType.CASH}>현금</option>
      {/* <option value={PaymentType.CREDIT}>카드</option> */}
    </select>
  )
}

export default PaymentMethod
