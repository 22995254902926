import React, { useContext } from "react"
import { useMediaQuery } from "react-responsive"
import { globalContext } from "../../../contexts/GlobalContext"
import { MarginStyle } from "../../../types"
import CartProductItem from "../../molecule/CartProductItem"

import "./style.css"

interface CartTableProps {
  style?: MarginStyle
}

const CartTable = ({ style }: CartTableProps) => {
  const context = useContext(globalContext)

  return (
    <>
      <div className="desktop-cart-table-container">
        <table>
          <thead>
            <tr>
              <td className="table-column-container" style={{ paddingLeft: 15 }}>
                상품
              </td>
              <td className="table-column-container">수량</td>
              <td className="table-column-container">가격</td>
            </tr>
          </thead>
        </table>
        <div className="scrollable-table" style={{ paddingRight: 15, paddingLeft: 15 }}>
          <table>
            <tbody>
              {context?.deliveryReducer.deliveryStates.delivery.items.map(item => (
                <CartProductItem cartItem={item} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="mobile-cart-table-container">
        {context?.deliveryReducer.deliveryStates.delivery.items.map(item => (
          <CartProductItem cartItem={item} />
        ))}
      </div>
    </>
  )
}

export default CartTable
