import React, { useContext } from "react"
import { globalContext } from "../../../contexts/GlobalContext"
import CustomerInput from "../../molecule/CustomerInput"
import PaymentMethod from "../../molecule/PaymentMethod"

const InputSet = () => {
  const context = useContext(globalContext)

  return (
    <>
      <div className="v-box" style={{ marginTop: 20 }}>
        <span style={{
          fontSize: 12,
          lineHeight: "15px",
          fontWeight: 500,
          color: "#8e8e8e"
        }}>결제수단*</span>
        <PaymentMethod
          value={context?.deliveryReducer.deliveryStates.delivery.paymentType}
          setValue={context?.deliveryReducer.setPaymentMethod}
          style={{ marginBottom: 20, marginTop: 8 }}
        />
        <CustomerInput
          title="이름*"
          value={context?.deliveryReducer.deliveryStates.delivery.customer.name}
          setValue={context?.deliveryReducer.setCustomerName}
          placeholder="홍길동"
          maxLength={20}
          type="text"
        />
        <CustomerInput
          title="주소*"
          value={context?.deliveryReducer.deliveryStates.delivery.customer.address1}
          setValue={context?.deliveryReducer.setCustomerAddress1}
          placeholder="대잠동 센트럴하이츠 512동 602호"
          maxLength={60}
          type="text"
        />

        <CustomerInput
          title="전화번호*"
          value={context?.deliveryReducer.deliveryStates.delivery.customer.phone}
          setValue={newValue => {
            if (newValue.length > 11 || newValue === "e") return
            else if (newValue !== "") {
              let result = false
              for (let i = 0; i < newValue.length; i++) {
                let letter = newValue.charAt(i)
                if (!(letter >= "0" && letter <= "9")) {
                  result = true
                  break
                }
              }
              if (result) return
            }
            context?.deliveryReducer.setCustomerPhone(newValue)
          }}
          style={{ marginBottom: 0 }}
          placeholder="01012345678"
          type="text"
        />
      </div>
      <div className="extra-container">
        <CustomerInput
          title="포인트 적립 번호"
          value={context?.deliveryReducer.deliveryStates.delivery.pointNumber}
          setValue={newValue => {
            if (newValue.length > 4 || newValue === "e") return
            else if (newValue !== "") {
              let result = false
              for (let i = 0; i < newValue.length; i++) {
                let letter = newValue.charAt(i)
                if (!(letter >= "0" && letter <= "9")) {
                  result = true
                  break
                }
              }
              if (result) return
            }
            context?.deliveryReducer.setPointNumber(newValue)
          }}
          placeholder="전화번호 뒷 4자리"
          maxLength={4}
          type="text"
        />
        <CustomerInput
          title="메모"
          value={context?.deliveryReducer.deliveryStates.delivery.note}
          setValue={context?.deliveryReducer.setDeliveryNote}
          placeholder="메시지"
          maxLength={100}
          type="text"
        />
      </div>
    </>
  )
}

export default InputSet
