import React from "react"
import { CountValue, MarginStyle } from "../../../types"

import "./style.scss"

interface ItemCounterStyle {
  style?: MarginStyle
  count: number
  onClick(value: CountValue): void
}

//TODO: globalContext에 Cart만들어지면 dispatch로 처리하게 해야함
const ItemCounter = ({ style, count, onClick }: ItemCounterStyle) => {
  return (
    <div className="h-box item-counter-container" style={style}>
      <button
        className="button-container"
        disabled={count == 1}
        onClick={() => onClick(CountValue.DECREASE)}
      >
        <img className="button-image" src={require("../../../images/minus-white.svg")} />
      </button>
      <span className="item-counter-individual-container">{count}</span>
      <button className="button-container" onClick={() => onClick(CountValue.INCREASE)}>
        <img className="button-image" src={require("../../../images/plus-white.svg")} />
      </button>
    </div>
  )
}

export default ItemCounter
