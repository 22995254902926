import React, { useContext } from "react"
import { globalContext } from "../../../contexts/GlobalContext"

const CountText = () => {
  const context = useContext(globalContext)

  return (
    <span className="information-text" style={{marginBottom: 8,}}>
      {context?.deliveryReducer.deliveryStates.delivery.items.length}
      개의 상품
    </span>
  )
}

export default CountText
