import { css } from "@emotion/react"
import React from "react"
import Modal from "react-modal"
import { flexBoxCss } from "../../../styles"
import { PRIVACY_POLICY } from "../../../values"
const styles = require("./style.module.scss")

interface PrivacyModalProps {
  isOpen: boolean
  setIsOpen: any
}

const modalTitle = css`
  font-weight: 600;
`

const closeButton = css`
  width: 20px;
  height: 20px;
  cursor: pointer;
`

const modalButton = css`
  ${flexBoxCss("v", "center", "center")}
  background-color: #B53843;
  color: #ffffff;
  height: 44px;
  border: none;
  margin: none;
  cursor: pointer;
`

const PrivacyModal = ({ isOpen, setIsOpen }: PrivacyModalProps) => {
  Modal.setAppElement("body")

  return (
    <Modal isOpen={isOpen} className={styles["modal"]} overlayClassName={styles["overlay"]}>
      <div className="v-box">
        <div className="h-box-spaced" style={{ marginBottom: 20 }}>
          <span css={modalTitle}>개인정보 취급 방침</span>
          <img
            css={closeButton}
            onClick={() => setIsOpen(false)}
            src={require("../../../images/icons8-multiply_black.svg")}
          />
        </div>
        <pre style={{ fontSize: 14, whiteSpace: "pre-wrap" }}>{PRIVACY_POLICY}</pre>
        <button css={modalButton} onClick={() => setIsOpen(false)}>
          닫기
        </button>
      </div>
    </Modal>
  )
}

export default PrivacyModal
