import React, { useContext, useState } from "react"
import { PaymentType } from "../../../API"
import { globalContext } from "../../../contexts/GlobalContext"

const SubmitButton = () => {
  const context = useContext(globalContext)

  return (
    <button
      className="big-button"
      disabled={!(context?.deliveryReducer.validateDelivery()==="OK") || !context.storeHourHook.isDeliverable() || context.deliveryReducer.isSending}
      onClick={async() => {
        if(context?.storeHourHook.isDeliverable()) {
          let alertMessage = ""

          alertMessage += "이름 : " + context.deliveryReducer.deliveryStates.delivery.customer.name + "\n"
          alertMessage += "주소 : " + context.deliveryReducer.deliveryStates.delivery.customer.address1 + "\n"
          alertMessage += "전화번호 : " + context.deliveryReducer.deliveryStates.delivery.customer.phone + "\n"
          alertMessage += "결제방법 : " + (context.deliveryReducer.deliveryStates.delivery.paymentType===PaymentType.CASH ? "현금결제" : "카드결제") + "\n"
          alertMessage += "포인트번호 : " + context.deliveryReducer.deliveryStates.delivery.pointNumber + "\n\n"
          alertMessage += "상기의 정보로 주문을 접수하시겠습니까?"

          if(confirm(alertMessage)) {
            context?.deliveryReducer.submitDelivery()
          }
        }
      }}
    >
      {context?.deliveryReducer.isSending ? "주문 전송 중" : "주문"}
    </button>
  )
}

export default SubmitButton
