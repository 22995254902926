import React, { useContext } from "react"
import { globalContext } from "../../../contexts/GlobalContext"
import { numberWithCommas } from "../../../functions"
import { CURRENCY_WON } from "../../../values"

const CurrencyText = ({ style }: {style?: any}) => {
  const context = useContext(globalContext)

  return (
    <div style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-end",
    }}>
      <span style={{ fontSize: 22, fontWeight: 700, ...style }}>
        {numberWithCommas(context?.deliveryReducer.getCartItemTotalPrice()!)}
      </span>
      {/* <span style={{
        fontSize: 14,
        paddingTop: 2
      }}>원</span> */}
    </div>
  )
}

export default CurrencyText
